import React, {useEffect} from "react"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"

type Props = {
	bannerTitle: string
	listTitle: string
	listItemOne?: string
	listItemTwo?: string
	listItemThree?: string
	bgImageSrc: string
	typeform: any
    bannerPadding?: string
    typeformWidth?: string | number
    typeformHeight?: string | number
	isStepsWithIcons?: boolean
	stepsWithIconsContent?:any[]
}

const BannerWithForm: React.FC<Props> = ({typeformWidth, typeformHeight, bannerTitle, listTitle, listItemOne, listItemTwo, listItemThree, bgImageSrc, typeform, bannerPadding, isStepsWithIcons, stepsWithIconsContent}) => {
	useEffect(() => {
		const script = document.createElement("script")
		script.src = "//embed.typeform.com/next/embed.js"
		script.async = true
		document.body.appendChild(script)

		return () => {
			document.body?.removeChild(script)
		}
	}, [])

	return (
		<>
			<section className="d-none d-md-flex justify-content-between align-items-center banner-with-form container position-relative"
				style={{
					backgroundImage: `url(${bgImageSrc})`,
					backgroundPosition: "center",
					backgroundSize: "cover",
					padding: bannerPadding ? bannerPadding : "48px 72px"
				}}>
				<div className="text-section col-6">
					<Typography variant="heading1"
						semanticTag="h1"
						className="text-white mb-4 pb-3 text-start"
						style={{lineHeight: "64px", fontSize: 48}}>
						{bannerTitle}
					</Typography>
					{isStepsWithIcons && stepsWithIconsContent?.length ? <div className="text-section col-12 ">
						<div className="instruction-steps">
							<Typography variant="bodySmBlack"
								className="mb-2 text-white mt-3 pb-1">{listTitle}</Typography>
							{stepsWithIconsContent.map((step, index) => (<div key={index}
								className="step">
								<Typography style={{minHeight: "3rem"}}
									variant="heading3SmBlack"
									className="number text-dark">{step?.header}</Typography>
								<Typography variant="bodyLg"
									className="text-white">{step?.description}</Typography>
							</div>))}
						</div>
					</div> : <div className="instruction-steps">
						<Typography variant="bodyLg"
							className="text-white mb-3 pb-1">{listTitle}</Typography>
						<div className="step">
							<Typography variant="heading3SmBlack"
								className="number text-dark">1</Typography>
							<Typography variant="bodyLg"
								className="text-white">{listItemOne}</Typography>
						</div>
						<div className="step">
							<Typography variant="heading3SmBlack"
								className="number text-dark">2</Typography>
							<Typography variant="bodyLg"
								className="text-white">{listItemTwo}</Typography>
						</div>
						<div className="step">
							<Typography variant="heading3SmBlack"
								className="number text-dark">3</Typography>
							<Typography variant="bodyLg"
								className="text-white">{listItemThree}</Typography>
						</div>
					</div>}
				</div>
				<div className="form-section col-4 d-flex align-items-center bg-white position-relative"
					style={{width: typeformWidth ? typeformWidth : "100%", height: typeformHeight ? typeformHeight : "100%"}}>
					{typeform}
				</div>
			</section>
			<section className="container d-md-none banner-form-mobile">
				<div className="col-12 info-card-image position-relative">
					<Image src={bgImageSrc}
						layout={"fill"}
						objectFit={"cover"}
						objectPosition="top"/>
					<Typography variant="heading1Black"
						className="position-absolute text-white info-card-title pe-2"
						style={{zIndex: 10}}>
						{bannerTitle}
					</Typography>
				</div>
				{isStepsWithIcons && stepsWithIconsContent?.length ? <div className="text-section col-12 ">
					<div className="instruction-steps">
						<Typography variant="bodySm"
							className="mb-2 mt-3 pb-1">{listTitle}</Typography>
						{stepsWithIconsContent.map((step, index) => (<div key={index}
							className="step">
							<Typography variant="heading3SmBlack"
								className="number text-dark">{step?.header}</Typography>
							<Typography variant="bodySm">{step?.description}</Typography>
						</div>))}
					</div>
				</div> : <div className="text-section col-12 ">
					{/* TODO: This should be refactored to an easier implementation like above */}
					<div className="instruction-steps">
						<Typography variant="bodySm"
							className="mb-2 mt-3 pb-1">{listTitle}</Typography>
						<div className="step">
							<Typography variant="heading3SmBlack"
								className="number text-dark">1</Typography>
							<Typography variant="bodySm">{listItemOne}</Typography>
						</div>
						<div className="step">
							<Typography variant="heading3SmBlack"
								className="number text-dark">2</Typography>
							<Typography variant="bodySm">{listItemTwo}</Typography>
						</div>
						<div className="step">
							<Typography variant="heading3SmBlack"
								className="number text-dark">3</Typography>
							<Typography variant="bodySm">{listItemThree}</Typography>
						</div>
					</div>
				</div>}
				<div className="form-section col-12 mt-4 pt-3 position-relative">
					{typeform}
				</div>
			</section>
		</>
	)
}

export default BannerWithForm
